
















































































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {Customer} from "@/models/customer";
import {Station} from "@/models/station";
import moment from "moment/moment";

const StationStore = namespace('station');
const CustomerStore = namespace('customer');

@Component({})
export default class EditCustomerComponent extends Vue {

  data() {
    return {
      valid: false,
      required(propertyType: any) {
        return (v: any) => v && v.length > 0 || `Bitte Feld ausfüllen`;
      },
      maxLength(propertyType: any, maxLength: number) {
        return (v: any) => v && v.length <= maxLength || `${propertyType}  must have max  ${maxLength} characters`;
      },
      minLength(propertyType: any, min: number) {
        return (v: any) => v && v.length >= min || `${propertyType}  must have minimum  ${min} characters`;
      }
    }
  }

  @CustomerStore.Getter('selectedCustomer')
  public customer!: Customer;

  @StationStore.Action("createStation")
  public createStation!: (payload: { customer: Customer, station: Station }) => Promise<Station>

  @StationStore.Action("updateStation")
  public updateStation!: (payload: { station: Station }) => Promise<Station>


  @Prop({default: () => new Station({})})
  public station!: Station;

  public editStation?: Station;

  public menu: boolean = false;
  public nextMaintenanceMenu: boolean = false;

  @Watch("station", {immediate: true})
  public stationChanged() {
    if (this.station) {
      this.editStation = new Station(this.station);
    }
  }

  public closeDialog() {
    this.$emit('closeDialog', false);
  }

  public async saveStation() {
    try {
      if (this.editStation!.id) {
        await this.updateStation({station: this.editStation!});
      } else {

        await this.createStation({customer: this.customer, station: this.editStation!});
      }

      this.$emit('closeDialog', true);
    } catch (e) {
      if (e.code === 409) {
        this.$notify({
          group: 'fahlke',
          title: 'Fehler',
          text: 'Station existiert bereits',
          type: 'error'
        });
      } else {
        this.$notify({
          group: 'fahlke',
          title: 'Fehler',
          text: 'Fehler beim Speichern',
          type: 'error'
        });
      }
    }
  }

  public formattedDate(dateString: string) {
    if (!dateString) return null;
    return moment(dateString).format('DD.MM.YYYY');
  }
}

